
import { Options, Vue } from "vue-class-component";
import AchievementsPlaque from "@/components/achievements/AchievementsPlaque.vue";
import { userAPI } from "@/utils/apiCalls";
import { achievement } from "@/utils/Types";

@Options({
  async beforeMount() {
    this.plaques = await userAPI.getAchievements();
  },
  components: {
    AchievementsPlaque,
  },
  computed: {
    //Sort achievement plaques
    sortPlaques() {
      return this.plaques.sort((left: achievement, right: achievement) => {
        if (left.earned === right.earned) {
          return left.achievementId > right.achievementId ? 1 : -1;
        }
        return left.earned < right.earned ? 1 : -1;
      });
    },
  },
  data() {
    return {
      plaques: [],
    };
  },
})
export default class AchievementsBlock extends Vue {}
