
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    plaque: {
      required: true,
      type: Object,
    },
  },
  methods: {
    /**
     * Search achievement image
     * @param slug Achievement slug
     */
    tryGetImage(slug: string) {
      try {
        return require(`@/assets/images/achievements/${slug}.svg`);
      } catch (e) {
        return "";
      }
    },
  },
})
export default class AchievementsPlaque extends Vue {}
