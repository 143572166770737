
import { Options, Vue } from "vue-class-component";
import MedalBlock from "@/components/achievements/MedalBlock.vue";
import AchievementsBlock from "@/components/achievements/AchievementsBlock.vue";
import QuestionButton from "@/components/QuestionButton.vue";

@Options({
  mounted() {
    this.updateQuestionButton();
  },
  data() {
    return {
      wikiSubjects: [],
    };
  },
  methods: {
    updateQuestionButton() {
      this.wikiSubjects.push(
        {
          wikiButton: { name: this.$t("wiki.stats"), item: "statsPage" },
        },
        {
          wikiButton: { name: this.$t("wiki.achev"), item: "achevPage" },
        }

        //here you can add more page references
      );
    },
  },

  components: {
    MedalBlock,
    AchievementsBlock,
    QuestionButton,
  },
})
export default class Profile extends Vue {}
